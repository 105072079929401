:root {
  --body-bg: #000;
  --terminal-bgcolor: #100c08;
  --terminal-border: #333;
  --prefix-color: #d1a5ff;
}

body {
  font-family: monospace;
  background-color: var(--body-bg);
  color: #fff;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.Topbar{
  background-color: #36454F;
  width: 60%;
  padding: 10px 10px 5px 10px;
  border-radius: 10px 10px 0 0;
  border: 1px solid var(--terminal-border);
  border-bottom: none;
}

.Topbar span i{
  padding: 5px;
}

.terminal {
  width: 60%;
  border: 1px solid var(--terminal-border);
  padding: 10px;
  background-color: var(--terminal-bgcolor);
  color: #fff;
  overflow-y: auto;
  white-space: pre-wrap;
  font-size: 1rem;
  height: 80%;
  border-radius: 0 0 10px 10px;
  border-top: none;
}

/* Styling the scrollbar for WebKit-based browsers */
.terminal::-webkit-scrollbar {
  width: 12px;
}

.terminal::-webkit-scrollbar-track {
  background: #333;
}

.terminal::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 6px;
  border: 3px solid #333;
}

.terminal::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

/* Styling the scrollbar for Firefox */
.terminal {
  scrollbar-width: thin;
  scrollbar-color: #555 #333;
}

.output div {
  margin-top: 10px;
}

.command {
  display: block;
  color: #ffc90f;
  margin-left: 10px;
}

.prefix-command{
  display: flex;
}

.result {
  display: block;
}

.inputbox {
  display: flex;
}

.prefix {
  user-select: none;
  color: #000;
  display: flex;
  border: 2px solid var(--terminal-bgcolor);
  font-size: 1rem;
  height: 1.1rem;
}

.pr-svg-c {
  background-color: var(--prefix-color);
  z-index: 2;
}

.pr-svg {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  color: transparent;
  user-select: none;
}

.pr-svg-l {
  transform: translateX(3px);
}
.pr-svg-r {
  transform: translateX(-3px);
}

.pr-svg-1 {
  background-color: var(--prefix-color);
  transform: skew(30deg);
  height: 0.55rem;
}
.pr-svg-2 {
  background-color: var(--prefix-color);
  transform: skew(-30deg);
  height: 0.55rem;
}

.input {
  flex: 1;
  background-color: transparent;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  color: #ffc90f;
  margin-left: 10px;
}

.textarea-box {
  display: flex;
}

.textarea-box textarea {
  color: #41FDFE;
  text-decoration: none;
  border: none;
  outline: none;
  background-color: var(--terminal-bgcolor);
  font-size: 1rem;
  padding: 0;
  margin: 0;
  margin-left: 9px;
}

.pyboxInput {
  color: #41FDFE;
}

.pyboxInput span {
  color: #FFFFFF;
}

.pyboxError {
  color: #EE4B2B;
}